import './App.scss';
import React from "react";
import { BrowserRouter as Router } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { Routes } from "./views/Routes";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import SocialProofPopup from "./components/SocialProofPopup";
import ProjectNavigationMenu from './components/ProjectNavigationMenu/ProjectNavigationMenu';

//  Sentry Error Tracking

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: "https://f90ec88cb7434d1eb06136f694a8d6fd@o4504680995880960.ingest.sentry.io/4504680996864000",
    integrations: [new BrowserTracing(), new Sentry.Replay()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1, // If the entire session is not sampled, use the below sample rate to
    // sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,


  });
}

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

function App() {

  //  Initialize facebook pixel using fbq.

  if (process.env.NODE_ENV === 'production') {
    window.fbq('init', '1060787968629013');
  }

  var referrer = document.referrer;
  var referral_url_cookie = getCookie("referral_url")
  if (!referral_url_cookie) {
    document.cookie = `referral_url=` + referrer + `;max-age=604800;domain=.makrealty.com;path=/`;
  }

  return (<>
    <Toaster />
    <SocialProofPopup />
    <Router basename="/book/">
      <ProjectNavigationMenu/>
    </Router>
    <Routes />
  </>);
}

export default App;
