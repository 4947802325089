import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './ProjectNavigationMenu.scss';

const ProjectNavigationMenu = () => {
  const [activeItem, setActiveItem] = useState('Vacation Rentals');
  const location = useLocation();

  const PROJECT_NAVIGATION_ITEMS = [
    { name: 'MAK Realty', active: false, url: 'https://makrealty.com/' },
    {
      name: 'Vacation Rentals',
      active: true,
      url: process.env.REACT_APP_WORDPRESS_SITE_URL,
    },
    {
      name: 'Miami Fontainebleau Penthouse',
      active: false,
      url: process.env.REACT_APP_SITE_URL + '/fontainebleau/tresor/penthouse-miami-beach',
    },
  ];

  const handleURLChange = () => {
    const pathname = window.location.pathname;
    
    if (pathname.includes('fontainebleau')) {
      setActiveItem('Miami Penthouses');
    } else {
      setActiveItem('Vacation Rentals');
    }
  };

  useEffect(() => {
    if (location.pathname.includes('fontainebleau')) {
      setActiveItem('Miami Penthouses');
    } else {
      setActiveItem('Vacation Rentals');
    }

    window.addEventListener('popstate', handleURLChange);

    window.addEventListener('hashchange', handleURLChange);

    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    window.history.pushState = function (...args) {
      originalPushState.apply(this, args);
      handleURLChange();
    };

    window.history.replaceState = function (...args) {
      originalReplaceState.apply(this, args);
      handleURLChange();
    };

    return () => {
      window.removeEventListener('popstate', handleURLChange);
      window.removeEventListener('hashchange', handleURLChange);
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    };
  }, [location.pathname]);

  return (
    <div className="project-navigation-menu">
      <div className="menu-container">
        {
          PROJECT_NAVIGATION_ITEMS.map(({ name, url }, index) =>
            <a
              key={index}
              target="_blank"
              rel="noopener noreferrer"
              href={url}
              className={`menu-item ${activeItem === name ? 'active' : ''}`}
            >
              {name}
            </a>
          )
        }
      </div>
    </div>
  );
};

export default ProjectNavigationMenu;
